// Bootstrap
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap-reboot';
@import "pluigns/lity.min.css";
@import "pluigns/sweetalert2.min.css";
@import "pluigns/nav.min.css";

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lato:ital,wght@0,300;0,400;0,700;1,700&display=swap');

//
@import "../animations";
@import "general";
@import 'login';
@import "nav";
