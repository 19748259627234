
$brand-primary: white;
$brand-secondary: #131D48;
$brand-third: #05509C;
$brand-alternative: #205993;
$brand-light: #BDCCD5;


$rangeColo1-one: #FFCFD2;
$rangeColo1-two: #FFE8E9;

$rangeColo2-one: #C4ACE6;
$rangeColo2-two: #F8F2F9;

$rangeColo3-one: #BBC7EC;
$rangeColo4-one: #F58B4D;
$rangeColo5-one: #99C9B9;
$rangeColo6-one: #FFF4D9;
$rangeColo7-one: #5B5B5B;

$fontBase: 'Lato', sans-serif;

body {
    font-family: $fontBase;
}

a {
    &:hover {
        color: $rangeColo4-one;
    }
}

#content {
    nav{
        z-index: 1 !important;
    }
}

.wAuxColor1 {
    color: $brand-primary;
    &:hover {
        color: $brand-primary;
    }
}
.wAuxColor2 {
    color: $brand-light;
    &:hover {
        color: $rangeColo3-one;
    }
}
.wAuxColor3 {
    color: $brand-third;

    &:hover {
        color: $rangeColo2-one;
    }
}

.input {
    padding: 8.5px 20px;
    width: 100%;
    height: 43px;
    background: #FFFFFF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    &.w-border {
        border: 1px solid lightgray;
    }

    &[type=radio] {
        min-width: 20px;
        box-shadow: none;
    }
    &[type=checkbox] {
        min-width: 30px;
        box-shadow: none;
    }
}

form {
    label {
        color: $rangeColo7-one;
        font-weight: 500;
    }
    input, select {
        padding: 8.5px 20px;
        min-width: 262px;
        height: 43px;
        background: #FFFFFF;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        &.w-border {
            border: 1px solid lightgray;
        }

        &[type=radio] {
            min-width: 20px;
            box-shadow: none;
        }
        &[type=checkbox] {
            min-width: 30px;
            box-shadow: none;
        }
    }

    select {
        width: 400px;
    }
}

.btnStd {
    background-color: $brand-secondary;
    padding-bottom: 15.5px;
    padding-top: 15.5px;
    height: 51px;
    border-radius: 36px;
    min-width: 145px;
    text-transform: uppercase;
    color: $brand-primary;
    text-align: center;

    &.w-shadow {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    }

    &.primary {
        background-color: $brand-secondary;
        color: $brand-primary;
    }
    &.secondary {
        background-color: $brand-secondary;
        color: white;
    }
    &.third {
        background-color: $brand-third;
        color: white;
    }
    &.type1 {
        background-color: $rangeColo4-one;
        color: white;
    }
    &.type2 {
        background-color: $rangeColo5-one;
        color: white;
    }
    &.type3 {
        background-color: $rangeColo3-one;
        color: white;
    }

    &.disabled {
        opacity: 0.6;
    }
}

.invalid-feedback {
    color: red;
}

.no-padding {
    padding: 0;
}

a{
    text-decoration: none;
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: .9rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;

    &.show {
        display: block;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        padding: .25rem 1.5rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
    }
}

.file-input {
    left: 0;
    height: 100%;
    top: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
}

.position-relative {
    position: relative;
}

.action-btn {
    display: inline-block;
    text-align: center;
    height: 40px;
    line-height: 40px;

    &.wSpace {
        white-space: normal;
        line-height: normal;
    }
}

.w-20 {
    width: 20%;
}
.w-30 {
    width: 30%;
}
.w-100 {
    width: 100%;
}

.rounded-circle {
    border-radius: 50%;
    border: 1px solid lightgray;
    border-width: 3px;
}

.banner-no-visible, .no-visible {
    opacity: 0.3;
}

.banner-alert, .alert {
    color: white;
    line-height: 60px;
    height: 60px;
    width: 100%;
    padding-left: 15px;
    border-radius: 10px;

    &.alert-success {
        background-color: green;
    }
    &.alert-warning {
        background-color: #ff8a00;
    }
    &.alert-error {
        background-color: #db3d3f;
    }
}

.swal2-icon {
    font-size: 15px !important;
}

.ui-tooltip {
    max-width: 250px;
    height: auto;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    background: black;
    border: 1px solid #737373;
    color: white;
    //margin: 4px 121px 0 5px;
    padding: 20px;
    text-align: left !important;
    display: inline-block;
}

.ui-helper-hidden-accessible {
    display: none;
}

#content {
    margin-left: 20rem;
    width: 70%;

    @media (min-width: 1799px) {
        margin-left: 20rem;
        width: 60%;
        margin-right: 20rem;
    }
}

.main-content {
    margin: 0;
    width: 100%;

    @media (min-width: 1190px) {
        max-width: 900px;
    }
    @media (min-width: 1599px) {
        max-width: 1140px;
        width: auto;
    }
    @media (min-width: 1799px) {
        margin: 0 auto;
    }

}

.w-padding-lg-left {
    padding-left: 85px;
}

.img-chili {
    display: inline-block;
    width: 100px;
}

[type=radio] + img {
    cursor: pointer;
    margin-top: -12px;
    margin-left: 5px;
}

.wrap-feature {
    box-shadow: 3px 5px 5px -4px black;
    border-radius: 15px;
    min-height: 230px;
    margin-top: 15px;
    margin-bottom: 45px;
    margin-left: 15px;
    width: 15%;
    border: 1px solid lightgray;
    text-align: center;
    padding-top: 15px;
    float: left;

    &.small {
        min-height: 180px;
    }

    &.certificate {
        img {
            display: inline-block;
            height: auto;
            width: auto;
            max-width: 60%;
            min-height: auto;
            padding-top: 20px;
        }

    }

    .img-head {
        height: 50px;
        width: 100%;
    }

    img {
        display: inline-block;
        height: auto;
        min-height: 113px;
        width: auto;
        max-width: 60%;
    }
    label {
        display: block;
        margin-top: 10px;
    }
}


.productFeatures-list, .productWhereToBuy-list, .categories-list {

    .swal2-header {
        height: 40px;
    }
    .wrap-feature {
        cursor: pointer;
        width: 30%;

        &.selected {
            .icon {
                color: $brand-primary;
                opacity: 1;
            }
        }

        .icon {
            color: lightgray;
            font-size: 30px;
            position: relative;
            margin: 0 auto;
            display: inline-block;
            opacity: 0.8;
            top: 18px;
        }
    }
    .wrap-footer {
        height: 90px;
        width: 100%;
        position: absolute;
        bottom: -75px;

        button {
            width: 235px;
        }
    }

}

.productIngredients-list {

    .wrap-feature {
        cursor: pointer;
        min-height: 100px;
        width: 30%;

        &.selected {
            .icon {
                color: $brand-primary;
                opacity: 1;
            }
        }

        .icon {
            color: lightgray;
            font-size: 30px;
            position: relative;
            margin: 0 auto;
            display: inline-block;
            opacity: 0.8;
            top: 18px;
        }

        label {
            margin: 0;
            height: 20px;
        }
    }
}

.categories-list {

    .wrap-feature {
        cursor: pointer;
        min-height: 100px;
        width: 40%;

        &.selected {
            .icon {
                color: $brand-primary;
                opacity: 1;
            }
        }

        .icon {
            color: lightgray;
            font-size: 30px;
            position: relative;
            margin: 0 auto;
            display: inline-block;
            opacity: 0.8;
            top: 18px;
        }

        label {
            margin: 0;
            height: 20px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.recipeIngredients-list {

    .wrap-feature {
        cursor: pointer;
        min-height: 120px;
        width: 40%;

        &.selected {
            .icon {
                color: $brand-primary;
                opacity: 1;
            }
        }

        .icon {
            color: lightgray;
            font-size: 30px;
            position: relative;
            margin: 0 auto;
            display: inline-block;
            opacity: 0.8;
            top: 18px;
        }

        label {
            margin: 0;
            height: 20px;
            padding-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
            height: 40px;
        }
    }
}

.grid-img {
    position: absolute;
    right: 40%;
    top: 15px;

    label {
        color: lightgray;
    }
}

.badge-danger {
    display: block;
    color: red;
    font-weight: bold;
}

.tooltip-inner {
    color: white;
    background-color: $rangeColo7-one;
    box-shadow: 0px 0px 4px black;
    opacity: 1 !important;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    text-align: center;
    width: auto;
    z-index: 2000;
}
.tooltip.bs-tooltip-right .tooltip-arrow::before {
    border-right-color: $rangeColo7-one !important;
}
.tooltip.bs-tooltip-left .tooltip-arrow::before {
    border-left-color: $rangeColo7-one !important;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: $rangeColo7-one !important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: $rangeColo7-one !important;
}

.cursor-grabbing {
    cursor: grabbing;
}
.cursor-grab {
    cursor: grab;
}

.swal2-popup {
    padding-bottom: 4em !important;
    padding-top: 3em !important;
}

.main-paginate {
    display: flex;
    text-align: center;
    height: 55px;
    width: 100%;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;

    li {
        color: #5b5b5b;
        list-style: none;
        margin-left: 15px;
        margin-right: 15px;
        height: 55px;
        font-size: 16px;
        padding-top: 15px;
        width: 15px;

        a {
            color: #5b5b5b;
            display: block;
            height: 100%;
            width: 100%;
        }

        &.active {
            position: relative;
            color: $brand-primary;
            font-weight: 900;

            &:before {
                background-color: $brand-primary;
                content: "";
                height: 2px;
                position: absolute;
                width: 100%;
                bottom: 10px;
                left: 0;
            }
        }

        &.pagination {
            border-radius: 50%;
            width: 54px;
            height: 54px;
            display: block;
            margin-left: 30px;
            margin-right: 30px;
            padding: 0;

            svg {
                path {
                    fill: $brand-primary;
                }
            }

            &.next {
                transform: rotate(180deg);
            }

            &.disabled {
                svg {
                    path, rect {
                        fill: #5b5b5b;
                    }
                }
            }
        }
    }
}

.self-center {
    margin: 0 auto;
}

@keyframes floating {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    100% {
        transform: translatey(0px);
    }
}