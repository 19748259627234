body {
    &.login {
        background-image: url('/back/login/background.jpg');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        height: 100vh;
        margin-left: 0;

        #content {
            padding: 0 !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .logo {
            display: block;
            margin: 15% auto 45px auto;
            width: 260px;
        }

        .card {
            background-image: url('/back/login/back-wrap.png');
            //background-color: rgb(215, 232, 85, 0.6);
            background-repeat: repeat;

            -webkit-box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.5);
            box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.5);

            border: 0;
            border-radius: 20px;
            padding-top: 20px;
            padding-bottom: 20px;

            .btn-brand {
                width: 100%;
            }

            .card-body {
                display: flex;
                flex: auto;
                flex-direction: column;
                justify-content: flex-end;
                padding-top: .25rem;

                .form-group {
                    margin-bottom: 1rem;
                }

                .col-form-label {
                    padding-top: calc(.375rem + 1px);
                    padding-bottom: calc(.375rem + 1px);
                    margin-bottom: 0;
                    font-size: inherit;
                    line-height: 1.6;
                }

                .form-control {
                    display: block;
                    width: 100%;
                    height: calc(1.6em + .75rem + 2px);
                    padding: .375rem .75rem;
                    font-size: .9rem;
                    font-weight: 400;
                    line-height: 1.6;
                    color: #495057;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: .25rem;
                    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }

                button {
                    border: 0;
                }
            }

            .drone {
                position: absolute;
                bottom: 15px;
                left: 25px;
                width: 80px;
                transition: all 1s ease-out;

                &.move {
                    bottom: 300%;
                }
            }
        }
    }
}
